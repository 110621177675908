<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Add New Attribute Set</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/Set-attributes" class="active" target="_self"
                >Attribute Sets</a
              >
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Add New Attribute Set</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToSetAttributesList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Back to Attribute Sets List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Add New Attribute Set</h4>
            <form @submit.prevent="createAttributeSet" style="margin-top: 20px">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="form.name"
                  required
                />
              </div>
              <div class="d-flex justify-content-between">
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "AddSetAttributes",
  data() {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    async createAttributeSet() {
      try {
        await strapi.post("/set-attributes", { data: this.form });
        this.$router.push({ name: "SetAttributes" }); // Redirect to the attribute sets list
      } catch (error) {
        console.error("Error creating attribute set:", error);
        if (error.response && error.response.data) {
          console.error("Error details:", error.response.data);
        }
      }
    },
    goToSetAttributesList() {
      this.$router.push({ name: "SetAttributes" });
    },
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
