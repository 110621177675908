<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Create Attribute</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/attributes" class="active" target="_self">Attributes</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Create Attribute</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAttributeList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Back to Attribute List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Create Attribute</h4>
            <div class="btn-group" role="group" aria-label="Section selection">
              <button
                type="button"
                class="btn btn-secondary"
                :class="{ active: activeSection === 'general' }"
                @click="setSection('general')"
              >
                General
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                :class="{ active: activeSection === 'values' }"
                @click="setSection('values')"
              >
                Values
              </button>
            </div>
            <form @submit.prevent="handleSubmit" style="margin-top: 20px">
              <div v-if="activeSection === 'general'">
                <div class="form-group">
                  <label for="attributeSet">Attribute Set</label>
                  <select
                    id="attributeSet"
                    v-model="form.attributeSet"
                    class="form-control"
                    required
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      v-for="set in attributeSets"
                      :key="set.id"
                      :value="set.id"
                    >
                      {{ set.attributes.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="form.name"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="category">Category</label>
                  <select
                    id="category"
                    v-model="form.category"
                    class="form-control"
                    @change="handleCategoryChange"
                    required
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.attributes.name }}
                    </option>
                  </select>
                </div>

                <div v-if="selectedCategories.length" class="form-group">
                  <div
                    v-for="(category, index) in selectedCategories"
                    :key="category.id"
                    class="selected-category"
                  >
                    <span>{{ category.attributes.name }}</span>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="removeCategory(index)"
                    >
                      &times;
                    </button>
                  </div>
                </div>

                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="filterable"
                    v-model="form.filterable"
                  />
                  <label class="form-check-label" for="filterable">
                    Use this attribute for filtering products
                  </label>
                </div>

                <div class="d-flex justify-content-between">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="saveGeneral"
                  >
                    Save General Info
                  </button>
                </div>
              </div>

              <div v-if="activeSection === 'values'">
                <div
                  v-for="(value, index) in form.values"
                  :key="index"
                  class="row mb-2"
                >
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.values[index]"
                      placeholder="Value"
                    />
                  </div>
                  <div class="col-sm-2 d-flex align-items-center">
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="removeValue(index)"
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <button type="button" class="btn btn-primary" @click="addValue">
                  Add Value
                </button>

                <div class="d-flex justify-content-between mt-3">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="saveValues"
                  >
                    Save Values
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="setSection('general')"
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <div
      v-if="showModal"
      class="modal fade show"
      style="display: block"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Success</h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Attribute saved successfully!</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="goToAttributeList"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "AddAttributePage",
  data() {
    return {
      form: {
        attributeSet: "",
        name: "",
        category: "",
        filterable: false,
        values: [],
      },
      attributeSets: [],
      categories: [],
      selectedCategories: [],
      showModal: false,
      activeSection: "general",
      attributeId: null, // Track the ID of the created attribute
    };
  },
  async created() {
    await this.fetchAttributeSets();
    await this.fetchCategories();
  },
  methods: {
    async fetchAttributeSets() {
      try {
        const response = await strapi.get("/set-attributes");
        this.attributeSets = response.data.data || [];
      } catch (error) {
        console.error("Error fetching attribute sets:", error);
      }
    },
    async fetchCategories() {
      try {
        const response = await strapi.get("/categories");
        this.categories = response.data.data || [];
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    setSection(section) {
      this.activeSection = section;
    },
    handleCategoryChange() {
      const selectedCategory = this.categories.find(
        (cat) => cat.id === this.form.category
      );
      if (
        selectedCategory &&
        !this.selectedCategories.some((cat) => cat.id === selectedCategory.id)
      ) {
        this.selectedCategories.push(selectedCategory);
      }
      this.form.category = "";
    },
    removeCategory(index) {
      this.selectedCategories.splice(index, 1);
    },
    addValue() {
      this.form.values.push("");
    },
    removeValue(index) {
      this.form.values.splice(index, 1);
    },
    async saveGeneral() {
      try {
        // Save general data
        const attributeData = {
          attributeSet: this.form.attributeSet,
          name: this.form.name,
          categories: this.selectedCategories.map((cat) => cat.id), // Save selected categories
          filterable: this.form.filterable,
        };

        // Save the general information
        const response = await strapi.post("/attributes", {
          data: attributeData,
        });

        // Log the response for debugging
        console.log("Response from saving general info:", response);

        // Check if ID is returned and set it
        if (response.data && response.data.id) {
          this.attributeId = response.data.id; // Set the ID from the response
        } else {
          throw new Error("Attribute ID not received from server.");
        }

        this.setSection("values"); // Switch to the values section
      } catch (error) {
        console.error("Error saving general information:", error);
      }
    },
    async saveValues() {
      if (!this.attributeId) {
        console.error(
          "Attribute ID is not set. Save general information first."
        );
        return;
      }

      try {
        // Save values
        const valueData = this.form.values.map((value) => ({
          value,
          attribute: this.attributeId,
        }));

        // Save the values
        await strapi.post("/attribute-values", { data: valueData });

        // Show success modal
        this.showModal = true;
      } catch (error) {
        console.error("Error saving values:", error);
      }
    },
    closeModal() {
      this.showModal = false;
    },
    goToAttributeList() {
      this.closeModal();
      this.$router.push({ name: "AttributesPage" });
    },
    handleSubmit() {
      if (this.activeSection === "general") {
        this.saveGeneral();
      } else if (this.activeSection === "values") {
        this.saveValues();
      }
    },
  },
};
</script>
<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
  max-width: 500px;
}
.modal-content {
  padding: 20px;
}
.btn-group .btn.active {
  background-color: #007bff;
  color: #fff;
}
.selected-category {
  display: inline-flex;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  margin: 0.2rem;
}

.selected-category span {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.selected-category button {
  background: none;
  border: none;
  color: #d9534f;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.selected-category button:hover {
  color: #c9302c;
}
</style>
