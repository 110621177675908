<template>
  <div class="container-fluid">
    <div>
      <div class="col col-12">
        <h1>Subcategory List</h1>

        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/subcategory" class="active" target="_self"
                >Subcategory</a
              >
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Subcategory List</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAddSubcategory"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Add New Subcategory
          </button>
        </div>

        <div class="separator mb-5"></div>
      </div>
      <div class="row mb-5">
        <div class="col col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-2">
                <div class="float-md-right pt-1">
                  <div
                    id="ddown2"
                    class="dropdown b-dropdown d-inline-block btn-group"
                  >
                    <button class="btn dropdown-toggle" @click="toggleDropdown">
                      {{ entriesPerPage }}
                    </button>
                    <ul v-if="showDropdown" class="dropdown-menu">
                      <li @click.prevent="setEntriesPerPage(5)">5</li>
                      <li @click.prevent="setEntriesPerPage(10)">10</li>
                      <li @click.prevent="setEntriesPerPage(20)">20</li>
                      <li @click.prevent="setEntriesPerPage(30)">30</li>
                    </ul>
                  </div>
                </div>
                <div class="search-sm">
                  <input
                    type="text"
                    v-model="searchQuery"
                    @input="fetchSubcategories"
                    placeholder="Search..."
                    class="form-control"
                  />
                </div>
              </div>
              <table
                sort-desc.sync="false"
                role="grid"
                aria-busy="false"
                aria-colcount="4"
                aria-multiselectable="true"
                class="table b-table vuetable b-table-selectable b-table-select-multi"
                id="__BVID__301"
              >
                <thead role="rowgroup">
                  <tr role="row">
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>Category</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>Name</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>Status</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="4"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>Actions</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr
                    v-for="subcategory in paginatedSubcategories"
                    :key="subcategory.id"
                    role="row"
                    tabindex="0"
                    aria-selected="false"
                  >
                    <td aria-colindex="1" role="cell">
                      <span
                        v-for="category in subcategory.attributes.categories
                          .data"
                        :key="category.id"
                      >
                        {{ getCategoryName(category.id) }}
                      </span>
                    </td>
                    <td aria-colindex="2" role="cell">
                      {{ subcategory.attributes.name }}
                    </td>
                    <td aria-colindex="3" role="cell">
                      <span
                        v-if="subcategory.attributes.state === 'enabled'"
                        class="badge badge-success"
                        >Enabled</span
                      >
                      <span v-else class="badge badge-secondary">Disabled</span>
                    </td>
                    <td aria-colindex="4" role="cell">
                      <div class="">
                        <button
                          type="button"
                          class="btn icon-button large ml-1 btn-outline-success"
                          @click="goToEditSubcategory(subcategory.id)"
                        >
                          <i class="iconsminds-repair"></i>
                        </button>
                        <button
                          type="button"
                          class="btn icon-button large ml-1 btn-outline-danger"
                          @click="deleteSubcategory(subcategory.id)"
                        >
                          <i class="iconsminds-close"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul
                role="menubar"
                aria-disabled="false"
                aria-label="Pagination"
                class="pagination b-pagination pagination-sm justify-content-center"
              >
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === 1 }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to first page"
                    @click="goToPage(1)"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-control-start"></i>
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === 1 }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to previous page"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-left"></i>
                  </button>
                </li>
                <li
                  v-for="page in totalPages"
                  :key="page"
                  role="presentation"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                >
                  <button
                    role="menuitemradio"
                    type="button"
                    @click="goToPage(page)"
                    :aria-checked="currentPage === page"
                    :aria-posinset="page"
                    :aria-setsize="totalPages"
                    class="page-link"
                  >
                    {{ page }}
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to next page"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-right"></i>
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to last page"
                    @click="goToPage(totalPages)"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-control-end"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "SousCategoryList",
  data() {
    return {
      subcategory: null,
      subcategories: [],
      categories: [], // Add this to store category names
      totalRoutes: 0,
      totalPages: 0,
      entriesPerPage: 10, // Default value
      currentPage: 1,
      searchQuery: "",
      showDropdown: false, // For controlling the dropdown visibility
    };
  },
  computed: {
    paginatedSubcategories() {
      const startIndex = (this.currentPage - 1) * this.entriesPerPage;
      const endIndex = startIndex + this.entriesPerPage;
      return this.subcategories.slice(startIndex, endIndex);
    },
    SubCategoriesofcategory() {
      return this.subcategories &&
        this.subcategories.attributes &&
        this.subcategories.attributes.categories
        ? this.subcategories.attributes.categories.data
        : [];
    },
  },
  async created() {
    await this.fetchSubcategories();
    await this.fetchCategories();
  },
  watch: {
    entriesPerPage() {
      this.currentPage = 1; // Reset to the first page when changing the number of items per page
      this.fetchSubcategories();
    },
    currentPage() {
      this.fetchSubcategories();
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async fetchCategories() {
      try {
        const response = await strapi.get(`/categories`);
        this.categories = response.data.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchSubcategories() {
      try {
        const response = await strapi.get(
          `/sous-categories?populate=categories&filters[name][$contains]=${this.searchQuery}&pagination[page]=${this.currentPage}&pagination[pageSize]=${this.entriesPerPage}`
        );
        this.subcategories = response.data.data;
        this.totalRoutes = response.data.meta.pagination.total;
        this.totalPages = Math.ceil(this.totalRoutes / this.entriesPerPage);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    },
    getCategoryName(categoryId) {
      const category = this.categories.find((cat) => cat.id === categoryId);
      return category ? category.attributes.name : "Unknown Category";
    },
    async deleteSubcategory(subcategoryId) {
      try {
        const response = await strapi.delete(
          `/sous-categories/${subcategoryId}`
        );
        if (response.status === 200) {
          this.subcategories = this.subcategories.filter(
            (subcategory) => subcategory.id !== subcategoryId
          );
          alert("Subcategory deleted successfully!");
        } else {
          throw new Error("Failed to delete subcategory.");
        }
      } catch (error) {
        console.error("Failed to delete subcategory:", error);
        alert("Failed to delete subcategory. Please try again.");
      }
    },
    goToEditSubcategory(subcategoryId) {
      this.$router.push({
        name: "EditSousCategorie",
        params: { id: subcategoryId },
      });
    },
    goToAddSubcategory() {
      this.$router.push({ name: "AddSubcategory" });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    setEntriesPerPage(number) {
      this.entriesPerPage = number;
      this.showDropdown = false; // Close the dropdown
      this.fetchSubcategories(); // Fetch subcategories again with the new entries per page
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
</style>
