<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Add Child Category</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/childcategory" class="active" target="_self"
                >Child Category</a
              >
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Add Child Category</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToChildCategoryList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return To Child Category List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Add New Child Category</h4>
            <form
              @submit.prevent="createChildCategory"
              style="margin-top: 60px"
            >
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="category">Select Category</label>
                  <select
                    id="category"
                    v-model="form.category"
                    required
                    class="form-control"
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.attributes.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-sm-6">
                  <label for="subCategory">Select Sub Category</label>
                  <select
                    id="subCategory"
                    v-model="form.subCategory"
                    required
                    class="form-control"
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      v-for="subcategory in subcategories"
                      :key="subcategory.id"
                      :value="subcategory.id"
                    >
                      {{ subcategory.attributes.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <fieldset class="form-group col-sm-6">
                  <label for="name" class="col-form-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="form.name"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-6">
                  <label for="slug" class="col-form-label">Slug</label>
                  <input
                    type="text"
                    class="form-control"
                    id="slug"
                    v-model="form.slug"
                    required
                  />
                </fieldset>
              </div>

              <div
                class="col-mb-4 d-flex justify-content-center align-items-center"
              >
                <button type="submit" class="btn btn-outline-primary">
                  Add Child Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <div
      v-if="showModal"
      class="modal fade show"
      style="display: block"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Success</h5>
            <button
              type="button"
              class="close"
              @click="closeModal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Child Category added successfully!</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="goToChildCategoryList"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "CreateChildCategory",
  data() {
    return {
      form: {
        category: "",
        subCategory: "",
        name: "",
        slug: "",
      },
      categories: [],
      subcategories: [],
      showModal: false, // To control modal visibility
    };
  },
  async created() {
    await this.fetchCategories();
    await this.fetchSubcategories();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await strapi.get("/categories");
        this.categories = response.data.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchSubcategories() {
      try {
        const response = await strapi.get("/sous-categories");
        this.subcategories = response.data.data;
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    },
    async createChildCategory() {
      try {
        const childCategoryData = {
          name: this.form.name,
          slug: this.form.slug,
          categories: [this.form.category],
          sous_categories: [this.form.subCategory],
        };

        await strapi.post("/child-categories", { data: childCategoryData });

        // Show success modal
        this.showModal = true;
      } catch (error) {
        console.error("Error creating child category:", error);
        if (error.response && error.response.data) {
          console.error("Error details:", error.response.data);
        }
      }
    },
    closeModal() {
      this.showModal = false;
    },
    goToChildCategoryList() {
      this.closeModal(); // Hide modal
      this.$router.push({ name: "ChildCategory" }); // Navigate to the Child Category List page
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
  max-width: 500px;
}
.modal-content {
  padding: 20px;
}
</style>
