<template>
  <div class="container-fluid">
    <div>
      <div class="col col-12">
        <h1>Child Category List</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/childcategory" class="active" target="_self"
                >Child Category</a
              >
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Child Category List</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAddChildCategory"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Add New Child Category
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="row mb-5">
        <div class="col col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-2">
                <div class="float-md-right pt-1">
                  <div
                    id="ddown2"
                    class="dropdown b-dropdown d-inline-block btn-group"
                  >
                    <button class="btn dropdown-toggle" @click="toggleDropdown">
                      {{ entriesPerPage }}
                    </button>
                    <ul v-if="showDropdown" class="dropdown-menu">
                      <li @click.prevent="setEntriesPerPage(5)">5</li>
                      <li @click.prevent="setEntriesPerPage(10)">10</li>
                      <li @click.prevent="setEntriesPerPage(20)">20</li>
                      <li @click.prevent="setEntriesPerPage(30)">30</li>
                    </ul>
                  </div>
                </div>
                <div class="search-sm">
                  <input
                    type="text"
                    v-model="searchQuery"
                    @input="fetchChildCategories"
                    placeholder="Search..."
                    class="form-control"
                  />
                </div>
              </div>
              <table
                class="table b-table vuetable b-table-selectable b-table-select-multi"
              >
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Name</th>

                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="childCategory in paginatedChildCategories"
                    :key="childCategory.id"
                  >
                    <td aria-colindex="1" role="cell">
                      <span
                        v-for="category in childCategory.attributes.categories
                          .data"
                        :key="category.id"
                      >
                        {{ getCategoryName(category.id) }}
                      </span>
                    </td>
                    <td aria-colindex="1" role="cell">
                      <span
                        v-for="subcategory in childCategory.attributes.sous_categories
                          .data"
                        :key="subcategory.id"
                      >
                      {{ getSubCategoryName(subcategory.id) }}
                      </span>
                    </td>
                    <td>{{ childCategory.attributes.name }}</td>
                    <td>
                      <span
                        v-if="childCategory.attributes.state === 'enabled'"
                        class="badge badge-success"
                        >Enabled</span
                      >
                      <span v-else class="badge badge-secondary">Disabled</span>
                    </td>
                    <td>
                      <div>
                        <button
                          type="button"
                          class="btn icon-button large ml-1 btn-outline-success"
                          @click="goToEditChildCategory(childCategory.id)"
                        >
                          <i class="iconsminds-repair"></i>
                        </button>
                        <button
                          type="button"
                          class="btn icon-button large ml-1 btn-outline-danger"
                          @click="deleteChildCategory(childCategory.id)"
                        >
                          <i class="iconsminds-close"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul
                class="pagination b-pagination pagination-sm justify-content-center"
              >
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button
                    @click="goToPage(1)"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-control-start"></i>
                  </button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-left"></i>
                  </button>
                </li>
                <li
                  v-for="page in totalPages"
                  :key="page"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                >
                  <button @click="goToPage(page)" class="page-link">
                    {{ page }}
                  </button>
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-right"></i>
                  </button>
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    @click="goToPage(totalPages)"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-control-end"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "ChildCategoryList",
  data() {
    return {
      childCategories: [],
      categories: [],
      subcategories: [],
      totalRoutes: 0,
      totalPages: 0,
      entriesPerPage: 10,
      currentPage: 1,
      searchQuery: "",
      showDropdown: false,
    };
  },
  computed: {
    paginatedChildCategories() {
      const startIndex = (this.currentPage - 1) * this.entriesPerPage;
      const endIndex = startIndex + this.entriesPerPage;
      return this.childCategories.slice(startIndex, endIndex);
    },
    paginatedSubcategories() {
      if (!this.subcategories) return [];
      const startIndex = (this.currentPage - 1) * this.entriesPerPage;
      const endIndex = startIndex + this.entriesPerPage;
      return this.subcategories.slice(startIndex, endIndex);
    },
  },
  async created() {
    await this.fetchChildCategories();
    await this.fetchCategories();
    await this.fetchSubcategories();
  },
  watch: {
    entriesPerPage() {
      this.currentPage = 1;
      this.fetchChildCategories();
    },
    currentPage() {
      this.fetchChildCategories();
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async fetchCategories() {
      try {
        const response = await strapi.get("/categories");
        this.categories = response.data.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchSubcategories() {
      try {
        const response = await strapi.get("/sous-categories");
        if (response.data && response.data.data) {
          this.subcategories = response.data.data;
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    },
    async fetchChildCategories() {
      try {
        const response = await strapi.get(
          `/child-categories?populate=categories,sous_categories&filters[name][$contains]=${this.searchQuery}&pagination[page]=${this.currentPage}&pagination[pageSize]=${this.entriesPerPage}`
        );
        if (response.data && response.data.data) {
          this.childCategories = response.data.data;
          this.totalRoutes = response.data.meta.pagination.total;
          this.totalPages = Math.ceil(this.totalRoutes / this.entriesPerPage);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching child categories:", error);
      }
    },
    getCategoryName(categoryId) {
      const category = this.categories.find((cat) => cat.id === categoryId);
      return category ? category.attributes.name : "Unknown Category";
    },
    getSubCategoryName(subCategoryId) {
      const subCategory = this.subcategories.find(
        (subCat) => subCat.id === subCategoryId
      );
      return subCategory ? subCategory.attributes.name : "Unknown Sub Category";
    },
    goToPage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    setEntriesPerPage(entries) {
      this.entriesPerPage = entries;
      this.showDropdown = false;
      this.currentPage = 1;
      this.fetchChildCategories();
    },
    goToAddChildCategory() {
      this.$router.push({ name: "AddChildCategory" });
    },
    goToEditChildCategory(id) {
      this.$router.push({ name: "EditChildCategory", params: { id } });
    },
    async deleteChildCategory(id) {
      if (confirm("Are you sure you want to delete this child category?")) {
        try {
          await strapi.delete(`/child-categories/${id}`);
          this.fetchChildCategories();
        } catch (error) {
          console.error("Error deleting child category:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
</style>
