<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Edit Attribute</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/attributes" class="active" target="_self">Attributes</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Edit Attribute</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAttributeList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return To Attribute List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Edit Attribute</h4>
            <form @submit.prevent="updateAttribute" style="margin-top: 60px">
              <div class="row">
                <fieldset class="form-group col-sm-6">
                  <label for="name" class="col-form-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="form.name"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-6">
                  <label for="attribute" class="col-form-label"
                    >Attribute</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="attribute"
                    v-model="form.attribute"
                    required
                  />
                </fieldset>
              </div>

              <div class="row">
                <fieldset class="form-group col-sm-6">
                  <label for="filterable" class="col-form-label"
                    >Filterable</label
                  >
                  <select
                    id="filterable"
                    v-model="form.filterable"
                    class="form-control"
                    required
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </fieldset>
              </div>

              <div
                class="col-mb-4 d-flex justify-content-center align-items-center"
              >
                <button type="submit" class="btn btn-outline-primary">
                  Update Attribute
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "EditAttributePage",
  data() {
    return {
      form: {
        id: null,
        name: "",
        attribute: "",
        filterable: "true",
      },
    };
  },
  async created() {
    await this.fetchAttributeDetails();
  },
  methods: {
    async fetchAttributeDetails() {
      const attributeId = this.$route.params.id;
      if (!attributeId) {
        console.error("Attribute ID is undefined in route parameters.");
        return;
      }

      try {
        const response = await strapi.get(`/attributes/${attributeId}?populate=*`);
        if (response.data.data.attributes) {
          const attributes = response.data.data.attributes;
          this.form = {
            id: attributeId,
            name: attributes.name || "",
            attribute: attributes.attribute || "",
            filterable: attributes.filterable ? "true" : "false",
          };
          console.log("Fetched Attribute:", this.form);
        } else {
          console.error(
            "Attribute data not found in API response:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching attribute details:", error);
      }
    },
    async updateAttribute() {
      try {
        const attributeData = {
          name: this.form.name,
          attribute: this.form.attribute,
          filterable: this.form.filterable === "true",
        };

        const response = await strapi.put(`/attributes/${this.form.id}`, {
          data: attributeData,
        });

        console.log("Attribute updated:", response.data);
        alert("Attribute updated successfully!");
        this.goToAttributeList();
      } catch (error) {
        console.error("Error updating attribute:", error);
        if (error.response && error.response.data) {
          console.error("Error details:", error.response.data);
        }
      }
    },
    goToAttributeList() {
      this.$router.push({ name: "AttributesPage" }); // Replace with your actual route name
    },
  },
};
</script>
<style scoped></style>
