<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Edit Sub Category</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/app" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/app/category" class="active" target="_self">Category</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Edit Sub Category</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToCategoryList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return To Sub-Category List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Edit Sub Category</h4>
            <form @submit.prevent="updateSubCategory" style="margin-top: 60px">
              <div class="form-group col-sm-6">
                <label for="category">Select Category</label>
                <select
                  id="parentCategory"
                  v-model="form.category"
                  required
                  class="form-control"
                >
                  <option disabled value="">Please select one</option>
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.attributes.name }}
                  </option>
                </select>
              </div>

              <div class="row">
                <fieldset class="form-group col-sm-6">
                  <label for="name" class="col-form-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="form.name"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-6">
                  <label for="slug" class="col-form-label">Slug</label>
                  <input
                    type="text"
                    class="form-control"
                    id="slug"
                    v-model="form.slug"
                    required
                  />
                </fieldset>
              </div>

              <div
                class="col-mb-4 d-flex justify-content-center align-items-center"
              >
                <button type="submit" class="btn btn-outline-primary">
                  Update Sub Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "EditSubCategory",
  data() {
    return {
      form: {
        id: null,
        category: "",
        name: "",
        slug: "",
      },
      categories: [],
    };
  },
  async created() {
    await this.fetchCategories();
    await this.fetchSubCategoryDetails();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await strapi.get("/categories?populate=*");
        this.categories = response.data.data || [];
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchSubCategoryDetails() {
      const subCategoryId = this.$route.params.id;
      if (!subCategoryId) {
        console.error("Subcategory ID is undefined in route parameters.");
        return;
      }

      try {
        const response = await strapi.get(
          `/sous-categories/${subCategoryId}?populate=*`
        );
        if (response.data.data.attributes) {
          const attributes = response.data.data.attributes;
          this.form = {
            id: subCategoryId,
            name: attributes.name || "",
            slug: attributes.slug || "",
            price: attributes.price || 0,
            category: attributes.categories
              ? attributes.categories.data[0]?.id
              : null,
          };
          console.log("Fetched SubCategory:", this.form);
        } else {
          console.error(
            "SubCategory attributes not found in API response:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching subcategory details:", error);
      }
    },
    async updateSubCategory() {
      try {
        const formData = new FormData();
        const subCategoryData = {
          name: this.form.name,
          slug: this.form.slug,
          category: this.form.category, // Category ID as a single value
        };
        formData.append("data", JSON.stringify(subCategoryData));

        const response = await strapi.put(
          `/sous-categories/${this.form.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Subcategory updated:", response.data);
        alert("Subcategory updated successfully!");
        this.goToCategoryList();
      } catch (error) {
        console.error("Error updating subcategory:", error);
        if (error.response && error.response.data) {
          console.error("Error details:", error.response.data);
        }
      }
    },
    goToCategoryList() {
      this.$router.push({ name: "SousCategory" }); // Replace with your actual route name
    },
  },
};
</script>
<style scoped>
/* Add scoped styles here if needed */
</style>
