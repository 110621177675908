<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Add Category</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/app" class="active" target="_self">Acceuil</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/app/category" class="active" target="_self">Category</a>
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Add Category</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAddProduct"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return To Category List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Add New Category</h4>
            <form @submit.prevent="createCategory" style="margin-top: 60px">
              <div
                class="input-group-prepend col-sm-6"
                style="margin-left: -15px"
              >
                <div for="image" class="input-group-text">Image</div>
                <div class="custom-file b-form-file">
                  <input
                    type="file"
                    id="image"
                    ref="imageInput"
                    accept="image/*"
                    @change="handleImageChange"
                    class="custom-file-input"
                    required
                  />
                  <img
                    id="imagePreview"
                    src=""
                    alt="Image Preview"
                    style="
                      display: none;
                      position: relative;
                      top: -100px;
                      left: 827px;
                      width: 19%;
                      height: auto;
                      object-fit: cover;
                    "
                  />

                  <label
                    data-browse="Browse"
                    class="custom-file-label"
                    for="image"
                  >
                    <span
                      class="d-block form-file-text"
                      style="pointer-events: none"
                    >
                      Choose a file..
                    </span>
                  </label>
                </div>
              </div>

              <div class="row">
                <fieldset class="form-group col-sm-6">
                  <label for="name" class="col-form-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="category.name"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-6">
                  <label for="slug" class="col-form-label">Slug</label>
                  <input
                    type="text"
                    class="form-control"
                    id="slug"
                    v-model="category.slug"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-6">
                  <label for="meta_keywords" class="col-form-label"
                    >Meta Keywords</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="meta_keywords"
                    v-model="category.meta_keywords"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-6">
                  <label for="serial" class="col-form-label">Serial</label>
                  <input
                    type="text"
                    class="form-control"
                    id="serial"
                    v-model="category.serial"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-12">
                  <label for="description" class="col-form-label">
                    Description</label
                  >
                  <textarea
                    class="form-control"
                    id="description"
                    v-model="category.description"
                    rows="4"
                    required
                  ></textarea>
                </fieldset>
              </div>

              <div
                class="col-mb-4 d-flex justify-content-center align-items-center"
              >
                <button type="submit" class="btn btn-outline-primary">
                  Add Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "CreateCategory",
  data() {
    return {
      category: {
        name: "",
        description: "",
        image: null,
      },
    };
  },
  methods: {
    handleImageChange(event) {
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imagePreview = document.getElementById("imagePreview");
          imagePreview.src = e.target.result;
          imagePreview.style.display = "block";
        };
        reader.readAsDataURL(file);
        this.category.image = file;
      }
    },
    async createCategory() {
      try {
        const formData = new FormData();
        formData.append("data", JSON.stringify(this.category));
        formData.append("files.image", this.category.image);

        const response = await strapi.post("/categories", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Category created:", response.data);

        // Reset form fields after successful creation
        this.category = {
          name: "",
          description: "",
          image: null,
          imagePreview: null,
        };

        // Optionally, navigate to the Category List page or show a success message
        this.$router.push({ name: "CategoryPage" });
      } catch (error) {
        console.error("Error creating category:", error);
        // Handle error and show feedback to the user
      }
    },
    goToAddProduct() {
      // Use router.push to navigate programmatically
      this.$router.push({ name: "CategoryPage" }); // Replace 'CreateProduct' with your actual route name
    },
  },
};
</script>

<style scoped>
.file-upload-wrapper {
  position: relative;
  width: 100%;
  display: inline-block;
}

.file-upload-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.file-upload-button:hover {
  background-color: #0056b3;
}

.file-upload-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload-text {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}

/* Custom styles for the file input */
.file-upload {
  position: relative;
  display: inline-block;
  font-size: 16px;
  width: 100%;
}

.file-upload input[type="file"] {
  display: none; /* Hide the default file input */
}

.file-upload-label {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 100%;
}

.file-upload-label:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
</style>
