<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12">
        <h1>Edit Child Category</h1>
        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/childcategory" class="active" target="_self"
                >Child Category</a
              >
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Edit Child Category</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToChildCategoryList"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Return To Child Category List
          </button>
        </div>
        <div class="separator mb-5"></div>
      </div>
      <div class="col col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title">Edit Child Category</h4>
            <form
              @submit.prevent="updateChildCategory"
              style="margin-top: 60px"
            >
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="category">Select Category</label>
                  <select
                    id="category"
                    v-model="form.category"
                    required
                    class="form-control"
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.attributes.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-sm-6">
                  <label for="subCategory">Select Sub Category</label>
                  <select
                    id="subCategory"
                    v-model="form.subCategory"
                    required
                    class="form-control"
                  >
                    <option disabled value="">Please select one</option>
                    <option
                      v-for="subcategory in subcategories"
                      :key="subcategory.id"
                      :value="subcategory.id"
                    >
                      {{ subcategory.attributes.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <fieldset class="form-group col-sm-6">
                  <label for="name" class="col-form-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="form.name"
                    required
                  />
                </fieldset>
                <fieldset class="form-group col-sm-6">
                  <label for="slug" class="col-form-label">Slug</label>
                  <input
                    type="text"
                    class="form-control"
                    id="slug"
                    v-model="form.slug"
                    required
                  />
                </fieldset>
              </div>

              <div
                class="col-mb-4 d-flex justify-content-center align-items-center"
              >
                <button type="submit" class="btn btn-outline-primary">
                  Update Child Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strapi from "@/services/strapi";

export default {
  name: "EditChildCategory",
  data() {
    return {
      form: {
        id: null,
        category: "",
        subCategory: "",
        name: "",
        slug: "",
      },
      categories: [],
      subcategories: [],
    };
  },
  async created() {
    await this.fetchCategories();
    await this.fetchSubcategories();
    await this.fetchChildCategoryDetails();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await strapi.get("/categories?populate=*");
        this.categories = response.data.data || [];
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchSubcategories() {
      try {
        const response = await strapi.get("/sous-categories?populate=*");
        this.subcategories = response.data.data || [];
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    },
    async fetchChildCategoryDetails() {
      const childCategoryId = this.$route.params.id;
      if (!childCategoryId) {
        console.error("Child Category ID is undefined in route parameters.");
        return;
      }

      try {
        const response = await strapi.get(
          `/child-categories/${childCategoryId}?populate=*`
        );
        if (response.data.data.attributes) {
          const attributes = response.data.data.attributes;
          this.form = {
            id: childCategoryId,
            name: attributes.name || "",
            slug: attributes.slug || "",
            category: attributes.categories
              ? attributes.categories.data[0]?.id
              : null,
            subCategory: attributes.sous_categories
              ? attributes.sous_categories.data[0]?.id
              : null,
          };
          console.log("Fetched Child Category:", this.form);
        } else {
          console.error(
            "Child Category attributes not found in API response:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching child category details:", error);
      }
    },
    async updateChildCategory() {
      try {
        const childCategoryData = {
          name: this.form.name,
          slug: this.form.slug,
          categories: [this.form.category], // Ensure the category ID is in an array
          sous_categories: [this.form.subCategory], // Ensure the sub-category ID is in an array
        };

        const response = await strapi.put(`/child-categories/${this.form.id}`, {
          data: childCategoryData,
        });

        console.log("Child category updated:", response.data);
        alert("Child category updated successfully!");
        this.goToChildCategoryList();
      } catch (error) {
        console.error("Error updating child category:", error);
        if (error.response && error.response.data) {
          console.error("Error details:", error.response.data);
        }
      }
    },
    goToChildCategoryList() {
      this.$router.push({ name: "ChildCategory" }); // Replace with your actual route name
    },
  },
};
</script>

<style scoped>
/* Add scoped styles here if needed */
</style>
