<template>
  <div class="container-fluid">
    <div>
      <div class="col col-12">
        <h1>Attribute Sets</h1>

        <ul
          class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        >
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/" class="active" target="_self">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/set-attributes" class="active" target="_self"
                >Set Attributes</a
              >
            </li>
            <li class="breadcrumb-item active">
              <span aria-current="location">Attribute Sets List</span>
            </li>
          </ol>
        </ul>
        <div class="top-right-button-container">
          <button
            @click="goToAddSetAttributes"
            type="button"
            class="btn top-right-button btn-primary btn-lg"
          >
            Add New Attribute Set
          </button>
        </div>

        <div class="separator mb-5"></div>
      </div>
      <div class="row mb-5">
        <div class="col col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-2">
                <div class="float-md-right pt-1">
                  <div
                    id="ddown2"
                    class="dropdown b-dropdown d-inline-block btn-group"
                  >
                    <button class="btn dropdown-toggle" @click="toggleDropdown">
                      {{ routesPerPage }}
                    </button>
                    <ul v-if="showDropdown" class="dropdown-menu">
                      <li @click.prevent="setRoutesPerPage(5)">5</li>
                      <li @click.prevent="setRoutesPerPage(10)">10</li>
                      <li @click.prevent="setRoutesPerPage(20)">20</li>
                      <li @click.prevent="setRoutesPerPage(30)">30</li>
                    </ul>
                  </div>
                </div>
                <div class="search-sm">
                  <input
                    type="text"
                    v-model="searchQuery"
                    @input="fetchAttributeSets"
                    placeholder="Search..."
                    class="form-control"
                  />
                </div>
              </div>
              <table
                role="grid"
                aria-busy="false"
                aria-colcount="3"
                aria-multiselectable="true"
                class="table b-table vuetable b-table-selectable b-table-select-multi"
                id="__BVID__301"
              >
                <thead role="rowgroup">
                  <tr role="row">
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      aria-sort="ascending"
                      class="position-relative"
                    >
                      <div>Name</div>
                      <span class="sr-only"> (Click to sort descending)</span>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      aria-sort="none"
                      class="position-relative"
                    >
                      <div>Actions</div>
                      <span class="sr-only"> (Click to sort ascending)</span>
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr
                    v-for="set in paginatedAttributeSets"
                    :key="set.id"
                    role="row"
                    tabindex="0"
                    aria-selected="false"
                  >
                    <td aria-colindex="1" role="cell">
                      {{ set.attributes.name }}
                    </td>
                    <td aria-colindex="2" role="cell">
                      <div class="">
                        <button
                          type="button"
                          class="btn icon-button large ml-1 btn-outline-danger"
                          @click="deleteSetAttributes(set.id)"
                        >
                          <i class="iconsminds-close"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul
                role="menubar"
                aria-disabled="false"
                aria-label="Pagination"
                class="pagination b-pagination pagination-sm justify-content-center"
              >
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === 1 }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to first page"
                    @click="goToPage(1)"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-control-start"></i>
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === 1 }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to previous page"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-left"></i>
                  </button>
                </li>
                <li
                  v-for="page in totalPages"
                  :key="page"
                  role="presentation"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                >
                  <button
                    role="menuitemradio"
                    type="button"
                    @click="goToPage(page)"
                    :aria-checked="currentPage === page"
                    :aria-posinset="page"
                    :aria-setsize="totalPages"
                    class="page-link"
                  >
                    {{ page }}
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to next page"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-arrow-right"></i>
                  </button>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    role="menuitem"
                    aria-label="Go to last page"
                    @click="goToPage(totalPages)"
                    :disabled="currentPage === totalPages"
                    class="page-link"
                  >
                    <i class="simple-icon-control-end"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import strapi from "@/services/strapi";

export default {
  name: "SetAttributes",
  data() {
    return {
      attributeSets: [],
      totalRoutes: 0,
      totalPages: 0,
      routesPerPage: 10, // Default value
      currentPage: 1,
      searchQuery: "",
      showDropdown: false, // For controlling the dropdown visibility
    };
  },
  computed: {
    paginatedAttributeSets() {
      const startIndex = (this.currentPage - 1) * this.routesPerPage;
      const endIndex = startIndex + this.routesPerPage;
      return this.attributeSets.slice(startIndex, endIndex);
    },
  },
  async created() {
    await this.fetchAttributeSets();
  },
  watch: {
    routesPerPage(newVal, oldVal) {
      console.log(`routesPerPage changed from ${oldVal} to ${newVal}`);
      this.currentPage = 1; // Reset to the first page when changing the number of items per page
      this.fetchAttributeSets();
    },
    currentPage(newVal, oldVal) {
      console.log(`currentPage changed from ${oldVal} to ${newVal}`);
      this.fetchAttributeSets();
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async fetchAttributeSets() {
      console.log("Fetching attribute sets...");
      try {
        const response = await strapi.get(
          `/set-attributes?populate=*&filters[name][$contains]=${this.searchQuery}`
        );
        this.attributeSets = response.data.data;

        this.totalRoutes = this.attributeSets.length;
        this.totalPages = Math.ceil(this.totalRoutes / this.routesPerPage);
        console.log("Attribute sets fetched:", this.attributeSets);
        console.log("Total routes:", this.totalRoutes);
        console.log("Total pages:", this.totalPages);
      } catch (error) {
        console.error("Error fetching attribute sets:", error);
      }
    },

    async deleteSetAttributes(attributeSetId) {
      try {
        const response = await strapi.delete(
          `/set-attributes/${attributeSetId}`
        );

        if (response.status === 200) {
          // Remove deleted attribute set from the local array
          this.attributeSets = this.attributeSets.filter(
            (set) => set.id !== attributeSetId
          );

          alert("Attribute set deleted successfully!");
        } else {
          throw new Error("Failed to delete attribute set.");
        }
      } catch (error) {
        console.error("Failed to delete attribute set.", error);
        alert("Failed to delete attribute set. Please try again.");
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    setRoutesPerPage(number) {
      console.log("Setting routes per page to:", number);
      this.routesPerPage = number;
      this.showDropdown = false; // Close the dropdown
      this.fetchAttributeSets(); // Fetch attribute sets again with the new routes per page
    },
    goToAddSetAttributes() {
      // Use router.push to navigate programmatically
      this.$router.push({ name: "AddSetAttributes" });
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
.fixed-buttons {
  position: absolute;
  right: 20px; /* Adjust as needed */
  bottom: 7px; /* Adjust as needed */
}
</style>
